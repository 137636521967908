import { CREATE_BATCH_AND_SCHEDULES_URL } from '../../globals/urls'
import axios from '../../httpInstance/axios'

/**
 * @param {object} payload
 * create batch service
 * @payload
 * {
 *  course: <UUID>,
 *  mode: 'oh_meets' / 'zoom',
 *  batch_type: 'class'/'event'/'mock',
 *  center: <UUID>,
 *  status: 'active' / 'suspended',
 *  enrolment_capacity: <int>,
 *  fee: <int>,
 *  demo_capacity: <int>,
 *  schedule_start_date: 'YYYY-MM-DD',
 *  schedule_end_date: 'YYYY-MM-DD',
 *  timings: [
 *   {
 *    weekday: 1, //isoWeekday() - 1
 *    start_time: '12-30',
 *    end_time: '16-00'
 *   },
 *   {
 *    weekday: 2,
 *    start_time: '12-30',
 *    end_time: '16-00'
 *   },
 *  ]
 * }
 */

const createBatchAndSchedulesService = async (payload) => {
  const response = await axios.post(CREATE_BATCH_AND_SCHEDULES_URL, payload)
  return response.data
}

export default createBatchAndSchedulesService
