import moment from 'moment'

import updateBatchService from '../../batch/crud/update'
import {
  CANCEL_SCHEDULES_URL,
  SCHEDULES_URL,
  SESSION_DETAILS_EDIT_URL,
  TEACHER_ATTENDANCE_URL
} from '../../globals/urls'
import axios from '../../httpInstance/axios'

/**
 * @param {object} payload
 * update schedules
 * @payload
 * {
 *  type: 'all' / 'single',
 *  event: <event object of calendar event>
 * }
 * If type is all:
 *  It will update all calendar events of the current weekday
 * else:
 *  It will update the single calendar event
 */

const updateSchedules = async (payload) => {
  if (payload.type === 'single') {
    const requestData = {
      // date: moment(payload.event.start).format('YYYY-MM-DD'),
      start_time: moment(payload.event.start).format('HH:mm:ss'),
      end_time: moment(payload.event.end).format('HH:mm:ss'),
      room: payload.event._def.resourceIds[0]
    }
    if (payload.event?.teacherAttendance?.uuid) {
      const res = await axios.patch(
        `${TEACHER_ATTENDANCE_URL}/${payload.event?.teacherAttendance?.uuid}/`,
        { actual_teacher: payload.event.teacher }
      )
    }
    const response = await axios.patch(
      `${SCHEDULES_URL}/${payload.event.id}/`,
      requestData
    )
    return response.data
  } else if (payload.type === 'all') {
    const requestData = {
      ...payload.event,
      uuid: payload.event.uuid
    }
    const response = await updateBatchService(requestData)

    return { success: !!response.uuid }
  }
}

const cancelSchedule = async (payload) => {
  const { data, uuid } = payload

  const response = await axios.patch(`${CANCEL_SCHEDULES_URL}${uuid}/`, data)
  return response.data
}

const updateSessionDetails = async (payload) => {
  const { data, uuid } = payload
  const response = await axios.patch(
    `${SESSION_DETAILS_EDIT_URL}${uuid}/`,
    data
  )
  return response.data
}

export { updateSchedules, cancelSchedule, updateSessionDetails }
