// importing axios for network request
import axios from 'axios'

import { ACCESS_TOKEN_KEY, DEFAULT_AUTH_TOKEN } from '../../constants/auth.js'
import { getItem } from '../../helpers/cookie.js'
import { BASE_URL } from '../globals/urls.js'
// Get Authentication token
const getAuthToken = () => {
  let token = getItem(ACCESS_TOKEN_KEY)
  if (process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production') {
    if (token) {
      return token
    } else {
      return DEFAULT_AUTH_TOKEN
    }
  }
  return token
}
// Created Axios instance to use it in whole application
const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

// declaring interceptors
instance.interceptors.request.use(
  (response) => {
    const token = getAuthToken()
    // Check if User is authenticated or not
    if (token) {
      response.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
        source: 'alfred'
      }
    }
    return response
  },
  (error) => error
)

export default {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line indent
  get: instance.get,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line indent
  post: instance.post,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line indent
  patch: instance.patch,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line indent
  delete: instance.delete,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line indent
  options: instance.options
}
