/**
 * @param {*} rooms raw response.data of /room api
 * @returns {Array} array of {id,title}
 */
const roomToResources = (rooms) => {
  const resources = rooms?.results?.map(room => {
    let number;
    try{
      number = parseInt(room.name.replace("Room ",""))
    } catch(e) {
      number = room.name
    }
    return {
      id: room.uuid,
      title: room.name,
      order: number
    }
  });
  return resources;
}

export default roomToResources;