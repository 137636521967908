import React, { Component } from 'react'

// import * as Sentry from '@sentry/browser';
import classes from './ErrorBoundary.module.css'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // Sentry.withScope((scope) => {
    //     scope.setExtras(info);
    //     const eventId = Sentry.captureException(error);
    //     this.setState({ eventId });
    // });
    // Sentry.showReportDialog({ eventId: error })
    // sendErrorInfo(error, info.componentStack);

    console.log(error, errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return (
        <div className={classes['wrong-outer']}>
          <div className={classes['wrong-inner']}>
            <img className={classes['img-went-wrong']} alt="OPEN HOUSE" />
            <h2>Oops! Looks like something went wrong.</h2>
            <p>Give us a few moments to look in to it.</p>
          </div>
        </div>
      )
    }
    return children
  }
}

export default ErrorBoundary
