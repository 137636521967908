import React from "react";
const Home = React.lazy(() =>
  import("../../components/logic/Home")
);
const NotFound = React.lazy(() =>
  import("../../components/common/NotFound/NotFound")
);

export const default_route = {
  path: "/not-found",
  component: NotFound,
};

export const unauthenticated_routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/:view",
    component: Home,
  },
];

export const authenticated_routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/:view",
    component: Home,
  },
];
