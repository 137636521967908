import { ROOM_URL } from "../../globals/urls";
import axios from "../../httpInstance/axios";
import urlencode from "../../../helpers/urlHelpers";
/**
 * @param {object} filters room filters
 * @filters
 * {
 *  centre__uuid: <UUID>,
 * }
 */
const fetchRoomsService = async (filters) => {
  const response = await axios.get(urlencode(ROOM_URL, filters));
  return response.data;
}

export default fetchRoomsService;