import schedulerModel from './schedulerModel';
import modalsModel from './modalsModel';
import courseModel from './courseModel';
import batchModel from './batchModel';
import roomModel from './roomModel';
import tribeModel from './tribeModel';
/**
 * Global store of easy-peasy
 * All the models have its own
 * states, actions and thunks
 */
const globalStore = {
  modals: modalsModel,
  scheduler: schedulerModel,
  course: courseModel, //Not used yet
  batch: batchModel,
  room: roomModel,
  tribe: tribeModel
};

export default globalStore;
