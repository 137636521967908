import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'

import { getPalette } from './palette'

export const getTheme = () => {
    const palette = getPalette()

    return createMuiTheme({
        typography: {
            fontFamily: ['Avenir'].join(',')
        },
        shadows: ["none"],
        palette
    })
}