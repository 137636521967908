import { isEmpty as _isEmpty } from 'lodash'
import { object } from 'prop-types'
/**
 *
 * @param {*} params
 * @param {*} condition
 * It will return true if any of the params is null or empty
 * condition is and/or denotes || or &&
 * if condition is and then if all the elements of array is invalid then only true
 * else if one is invalid it will return true
 */
export const isArrayEmpty = (array) => array.length === 0
/**
 * @param {*} item
 * returns if item is empty or not
 */
export const isEmpty = (item) => {
  if (typeof item === 'number') {
    return false
  }

  return _isEmpty(item)
}
/**
 *
 * @param {*} params
 * @param {*} condition
 * returns if array or string is null or empty
 * also accepts array of items
 * condition param is optional but if we want to check if all
 * array elements are together empty or not then we will send 'and'
 * as second parama
 * @return true or false
 */
export function isNullOrEmpty(params, condition = 'or') {
  let isInvalid = false
  let counter = 0
  if (Array.isArray(params)) {
    if (params.length) {
      params.forEach((value) => {
        if (
          value === undefined ||
          value === null
          // value === 0 ||
          // value === false ||
          // value.length === 0 ||
          // value === ''
        ) {
          isInvalid = true
          counter += 1
        }
      })
    } else {
      isInvalid = true
    }
  } else if (
    params === undefined ||
    params === null ||
    // params === '' ||
    // params === 0 ||
    // params === false ||
    params === []
  ) {
    isInvalid = true
  }
  if (Array.isArray(params) && condition === 'and') {
    if (counter === params.length) {
      return true
    }
    return false
  }
  return isInvalid
}

export function isNullOrEmptyorZero(params, condition = 'or') {
  let isInvalid = false
  let counter = 0
  if (Array.isArray(params)) {
    if (params.length) {
      params.forEach((value) => {
        if (
          value === undefined ||
          value === null ||
          value === 0 ||
          value === false ||
          value.length === 0 ||
          value === ''
        ) {
          isInvalid = true
          counter += 1
        }
      })
    } else {
      isInvalid = true
    }
  } else if (
    params === undefined ||
    params === null ||
    params === '' ||
    params === 0 ||
    params === false ||
    params === []
  ) {
    isInvalid = true
  }
  if (Array.isArray(params) && condition === 'and') {
    if (counter === params.length) {
      return true
    }
    return false
  }
  return isInvalid
}
