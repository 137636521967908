import { action, thunk, computed } from 'easy-peasy';
import notify from '../helpers/notificationHelper';
import Console from '../helpers/console';
import fetchRoomsService from '../services/room/crud/fetch';
import roomToResources from '../services/room/converters/roomToResources';
/**
 * Room Model refers to all the api calls
 * and functionality related to room table operations
 * 
 * Simple crud operations to complex joining operations
 * related to room are here and will be added here
 */
/* eslint no-param-reassign: "error" */
const roomModel = {
  /**
   * Resources are the resource object of resource view
   * Computed by roomToResource function which acts as a converter
   * Structured as
   * [
   *  {
   *    id: string,
   *    title: string
   *  }
   * ]
   */
  resources: computed((state) => roomToResources(state.rooms)),
  /**
   * Raw response of rooms api call
   */
  rooms: [],

  /**
   * Used to set raw to response to rooms state
   */
  setRooms: action((state, payload) => {
    state.rooms = payload;
  }),

  /**
   * @param {object} payload room filters
   * @payload
   * {
   *  centre__uuid: <UUID>,
   * }
   */
  getRooms: thunk(async (actions, payload) => {
    try {
      const data = await fetchRoomsService(payload);
      actions.setRooms(data);
      return data;
    } catch(err) {
      Console.log(err);
      notify('Unable to get resources', '', 'danger');
      return err;
    }
  }),
};

export default roomModel;
