import React from 'react'
import { Switch } from 'react-router-dom'
import UnAuthenticatedRoutes from './UnAuthenticatedRoutes/UnAuthenticatedRoutes'
import AuthenticatedRoutes from './AuthenticatedRoutes/AuthenticatedRoutes'

const RouteSwitcher = (props) => (
  <Switch>
    {false ? <AuthenticatedRoutes /> : <UnAuthenticatedRoutes />}
  </Switch>
)
export default RouteSwitcher
