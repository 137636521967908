import axios from '../../httpInstance/axios';
import { TRIBE_URL } from '../../globals/urls';

/**
 * @param {object} payload
 * delete Batch
 * @payload
 * {
 *  eventType: 'batch' (optional),
 *  uuid: <UUID>,
 * }
 */

const deleteTribeService = async (payload) => {
  const { uuid } = payload;
  const response = await axios.delete(`${TRIBE_URL}/${uuid}/`);
  return response.data;
};

export default deleteTribeService;
