export const batchTypeColors = {
  class: {
    border: 'var(--oh-brand-7-dark)',
    fill: 'var(--oh-brand-7)'
  }, //blue
  explore: {
    border: 'var(--oh-brand-4-dark)',
    fill: 'var(--oh-brand-4)'
  }, //red
  event: {
    border: 'var(--oh-brand-6-dark)',
    fill: 'var(--oh-brand-6)'
  }, //green
  exploration: {
    border: 'var(--oh-brand-1-dark)',
    fill: 'var(--oh-brand-1)'
  },
  exploration_camp: {
    border: 'var(--oh-brand-1-dark)',
    fill: 'var(--oh-brand-3)'
  }, //yellow
  disabled: {
    border: 'var(--oh-black)',
    fill: 'var(--oh-black-light)'
  } //grey
}
