import axios from '../../httpInstance/axios';
import { COURSE_URL } from '../../globals/urls';
import urlencode from '../../../helpers/urlHelpers';
/**
 * @param {object} filters
 * fetch all the filtered courses
 * @filters
 * {
 *  teacher__user__uuid: <UUID>,
 * }
 */

const fetchCourses = async (filters) => {
  const response = await axios.get(urlencode(COURSE_URL, filters));
  return response.data;
};

export default fetchCourses;
