import { action, thunk, computed } from 'easy-peasy'
import moment from 'moment'

import Console from '../helpers/console'
import notify, { eventAlert } from '../helpers/notificationHelper'
import scheduleToEvents from '../services/scheduler/converters/scheduleToEvents'
import deleteSchedule from '../services/scheduler/crud/delete'
import { fetchAllSchedules as fetchSchedules } from '../services/scheduler/crud/fetch'
import {
  cancelSchedule,
  updateSchedules,
  updateSessionDetails
} from '../services/scheduler/crud/update'

/**
 * Initial Form Data
 */
const initialFormData = {
  teacher: '',
  substituted_teacher: '',
  course: '',
  centre: '',
  type: '',
  date_gte: moment().startOf('week').format('YYYY-MM-DD'),
  date_lte: moment().endOf('week').format('YYYY-MM-DD'),
  limit: 300,
  duration_type: 'weeks',
  duration_count: '1',
  error: false
}
/* eslint no-param-reassign: "error" */
const schedulerModel = {
  /**
   * Loading state for any kind of request in pending state
   */
  loading: false,
  /* start time*/
  start: null,
  /*end time*/
  end: null,
  /*selected event*/
  event: null,
  /*event edit*/
  isEvent: false,
  /*event edit id*/
  eventId: null,
  /* schedule */
  schedule: {},
  scheduleAttendanceData: {},
  /**
   * calendarApi is the API of calendar instance.
   * with this api we can change view of calendar.
   * We can get any state value of current calendar state.
   */
  calendarApi: null,
  /**
   * Raw schedules api response is stored here.
   * Generally used to compute the events object.
   */
  schedules: [],
  /**
   * dummyEvents is an array of dummy events
   *
   * dummy events are the custom slots or events which are just
   * selected in calendar but not converted to actual events yet.
   *
   * can be called as temporary events
   */
  dummyEvents: [],
  /**
   * computed property and derived from schedules state and dummyEvents
   * this object can directly be fed to full calendar as events
   * A single event object generally looks like this
   * {
   *  id: <UUID>, //schedule__uuid or custom uuid.v4()
   *  start: 'YYYY-MM-DDTHH:mm:ss',
   *  end: 'YYYY-MM-DDTHH:mm:ss',
   *  title: 'YYYY-MM-DDTHH:mm:ss',
   *  resourceId: <UUID> of room,
   *  extendedProps: {schedule: schedule object} (optional)
   * }
   */
  events: computed((state) => {
    return [...scheduleToEvents(state.schedules), ...state.dummyEvents]
  }),
  /**
   * formData are mainly used as filter to get schedules
   * they are updated from the header filters
   *
   * dates are controlled by various calendar buttons
   */
  formData: initialFormData,
  /**
   * weeklyRecur is used to check if we want to create schedule
   * with recurring option or not
   * If weeklyRecur is true:
   *   the schedules will be created till 31st of Mar 2021.
   * else:
   *   the schedules will be created only for the current week.
   */
  weeklyRecur: false,
  /**
   * Loading set
   */
  setLoading: action((state, payload) => {
    state.loading = payload
  }),

  setStart: action((state, payload) => {
    state.start = payload
  }),

  setEnd: action((state, payload) => {
    state.end = payload
  }),

  setSelectedEvent: action((state, payload) => {
    state.event = payload
  }),

  setIsEvent: action((state, payload) => {
    state.isEvent = payload
  }),

  setEventId: action((state, payload) => {
    state.eventId = payload
  }),

  setScheduleDetails: action((state, payload) => {
    state.schedule = payload
  }),
  /**
   * sets the schedules from getSchedules response
   */
  setScheduleAttendanceData: action((state, payload) => {
    state.scheduleAttendanceData = payload
  }),
  setSchedules: action((state, payload) => {
    state.schedules = payload
  }),
  /**
   * sets the calendar api when its instance is ready to be used
   */
  setCalendarApi: action((state, payload) => {
    state.calendarApi = payload
  }),
  /**
   * used to set filter/formData values
   * mainly used from the autocomplete component
   */
  setFormData: action((state, payload) => {
    state.formData[payload.target] = payload.value
  }),
  /**
   * reset schedule
   */
  resetFormData: action((state) => {
    state.formData = initialFormData
  }),
  /**
   * sets the dummy events
   * mainly used to empty the  dummyEvents array
   */
  setDummyEvents: action((state, payload) => {
    state.dummyEvents = payload
  }),
  /**
   * adds dummy event when used selects a slot in the calendar
   */
  addDummyEvent: action((state, payload) => {
    const newDummyEvents = [...state.dummyEvents, payload]
    state.dummyEvents = newDummyEvents
  }),
  /**
   * deletes the dummy event when user delets any selected slots
   * using the delete button in the modal
   */
  deleteDummyEvent: action((state, payload) => {
    state.dummyEvents = state.dummyEvents.filter(
      (event) => event.id !== payload
    )
  }),
  /**
   * updates dummy event if the user updates any dummy event
   * by dragging or extending any dummy event
   */
  updateDummyEvent: action((state, payload) => {
    state.dummyEvents = state.dummyEvents.map((event) => {
      if (event.id === payload.id) {
        return {
          id: payload.id,
          title: payload.title,
          start: payload.start,
          end: payload.end,
          color: 'var(--oh-brand-8)',
          resourceId: payload.resourceId
        }
      }
      return event
    })
  }),
  /**
   * used to set weeklyRecur boolean value
   * called when user checks or unchecks the checkbox in the create modal
   */
  setWeeklyRecur: action((state, payload) => {
    state.weeklyRecur = payload
  }),

  /**
   * used to get latest schedules with filters applied
   *
   * filters are mainly denoted by formData state
   * just pass updated formData state and it will fetch
   * latest schedules and update the computed event state
   * @payload
   * {
   *  teacher: <UUID>,
   *  course: <UUID>,
   *  centre: <UUID>,
   *  date_gte: 'YYYY-MM-DD',
   *  date_lte: 'YYYY-MM-DD'
   * }
   */
  getSchedules: thunk(async (actions, payload) => {
    actions.setLoading(true)
    try {
      const data = await fetchSchedules(payload)
      actions.setSchedules(data) // 👈 used to set schedules
      actions.setLoading(false)
      return data
    } catch (err) {
      Console.log(err)
      notify('Unable to fetch schedules', '', 'danger')
      eventAlert(err.message, 'ERROR', 'Unable to fetch schedules')
      actions.setLoading(false)
      return err
    }
  }),
  /**
   * @param {object} payload
   * updates current schedule or all schedules of the current weekday
   * @payload
   * {
   *  type: 'all' / 'single',
   *  event: <event object of calendar event>
   * }
   * If type is all:
   *  It will update all calendar events of the current weekday
   * else:
   *  It will update the single calendar event
   */
  performUpdate: thunk(async (actions, payload) => {
    try {
      const data = await updateSchedules(payload)
      if (data.success) {
        notify('Schedule updated successfully', '', 'success')
      } else {
        notify('Unable to update schedule', '', 'danger')
      }
      return data
    } catch (err) {
      Console.log(err)
      notify('Unable to update schedule', '', 'danger')
      return err
    }
  }),
  /**
   * @param {object} payload
   * delete current schedule
   * or delete all schedules
   * of the batch or all schedules of the current weeksday.
   * @payload
   * {
   *  type: single/all,
   *  data: <UUID>/
   *    {
   *      schedule_uuid: <UUID>,
   *      delete_all_of_batch: <boolean>
   *    }
   * }
   */
  deleteSchedule: thunk(async (actions, payload) => {
    try {
      const response = await deleteSchedule(payload)
      if (response.success) {
        notify('Schedule deleted successfully', '', 'success')
      } else {
        notify('Unable to delete schedules', '', 'danger')
      }
      return response
    } catch (err) {
      Console.log(err)
      notify('Unable to delete schedule', '', 'danger')
      return err
    }
  }),
  /**
   * @param {object} payload
   * cancel current schedule
   */
  cancelSchedule: thunk(async (actions, payload) => {
    try {
      const response = await cancelSchedule(payload)
      if (response.success) {
        notify('Event cancelled successfully', '', 'success')
      } else {
        notify('Unable to cancel event', '', 'danger')
      }
      return response
    } catch (err) {
      Console.log(err)
      notify('Unable to cancel event', '', 'danger')
      return err
    }
  }),

  sessionEdit: thunk(async (actions, payload) => {
    try {
      const response = await updateSessionDetails(payload)
      if (response.sucess || response.uuid) {
        notify('Session Details Upadated Successfully', '', 'sucess')
      } else {
        notify('Unable to update session details', '', 'danger')
      }
      return response
    } catch (err) {
      console.log(err)
      notify('Unable to update session details', '', 'danger')
      return err
    }
  })
}

export default schedulerModel
