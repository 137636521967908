import { action, thunk } from 'easy-peasy'

import Console from '../helpers/console'
import notify from '../helpers/notificationHelper'
import createBatchAndSchedulesService from '../services/batch/crud/create'
import deleteBatchService from '../services/batch/crud/delete'
import updateBatchService from '../services/batch/crud/update'
/**
 * Batch Model refers to all the api calls
 * and functionality related to batch table operations
 *
 * Simple crud operations to complex joining operations
 * related to batch are here and will be added here
 */
/* eslint no-param-reassign: "error" */
const batchModel = {
  // initialStates
  creatingBatch: false,
  batchDeleted: false,

  // actions
  setCreatingBatch: action((state, payload) => {
    state.creatingBatch = payload
  }),
  setBatchDeleted: action((state, payload) => {
    state.batchDeleted = payload
  }),

  /**
   * @param {object} payload
   * delete Batch
   * @payload
   * {
   *  eventType: 'batch' (optional),
   *  uuid: <UUID>,
   * }
   */
  deleteBatch: thunk(async (actions, payload) => {
    try {
      const response = await deleteBatchService(payload)
      if (response.success) {
        notify('Batch deleted successfully', '', 'success')
        actions.setBatchDeleted(true)
      } else {
        notify('Unable to delete batch', '', 'danger')
        actions.setBatchDeleted(false)
      }
      return response
    } catch (err) {
      Console.log(err)
      notify('Unable to delete batch', '', 'danger')
      return err
    }
  }),

  /**
   * @param {object} payload
   * create batch service
   * @payload
   * {
   *  course: <UUID>,
   *  mode: 'oh_meets' / 'zoom',
   *  batch_type: 'class'/'event'/'mock',
   *  center: <UUID>,
   *  status: 'active' / 'suspended',
   *  enrolment_capacity: <int>,
   *  fee: <int>,
   *  demo_capacity: <int>,
   *  schedule_start_date: 'YYYY-MM-DD',
   *  schedule_end_date: 'YYYY-MM-DD',
   *  timings: [
   *   {
   *    weekday: 1, //isoWeekday() - 1
   *    start_time: '12-30',
   *    end_time: '16-00'
   *   },
   *   {
   *    weekday: 2,
   *    start_time: '12-30',
   *    end_time: '16-00'
   *   },
   *  ]
   * }
   */
  createBatchAndSchedules: thunk(async (actions, payload) => {
    try {
      actions.setCreatingBatch(true)
      const data = await createBatchAndSchedulesService(payload)
      if (data.success) {
        notify('Batch created successfully', '', 'success')
      }
      actions.setCreatingBatch(false)
      console.log(data)
      return data
    } catch (err) {
      Console.log(err)
      notify('Unable to create batch and schedules', '', 'danger')
      actions.setCreatingBatch(false)
      return err
    }
  }),
  /**
   * @param {object} payload
   * create batch service
   * @payload
   * {
   *  course: <UUID>,
   *  mode: 'oh_meets' / 'zoom',
   *  batch_type: 'class'/'event'/'mock',
   *  center: <UUID>,
   *  status: 'active' / 'suspended',
   *  enrolment_capacity: <int>,
   *  fee: <int>,
   *  demo_capacity: <int>
   * }
   */
  updateBatch: thunk(async (actions, payload) => {
    try {
      const data = await updateBatchService(payload)
      if (data.success) {
        notify('Batch updated successfully', '', 'success')
      } else {
        notify('Unable to update batch', '', 'danger')
      }
      return data
    } catch (err) {
      console.log(err)
      notify('Unable to update batch', '', 'danger')
      return err
    }
  })
}

export default batchModel
