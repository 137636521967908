import { store as notificationStore } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Swal from 'sweetalert2'

/**
 * @param {string} title title of the notification
 * @param {string} message message of the notification
 * @param {string} type ["success", "danger"]
 * @param {int} duration in microseconds
 */
export default function notify(title, message, type, duration = 3000) {
  try {
    const msg = message || ' '
    notificationStore.addNotification({
      title,
      message: msg,
      type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration,
        onScreen: true
      },
      showIcon: false,
      pauseOnHover: true
    })
  } catch (e) {
    /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
    console.error(e)
  }
}

export const eventAlert = (message, type, title) => {
  if (type === 'SUCCESS') {
    Swal.fire(title || 'Success', message, type.toLowerCase())
  } else if (type === 'ERROR') {
    Swal.fire(title || 'Error', message, type.toLowerCase())
  }
}
