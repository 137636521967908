// import { jwt_decode as jwtDecode } from 'jwt-decode';
import { isNullOrEmpty } from './emptyHelper'
import notify from './notificationHelper'

import fullFieldPath from '../services/scheduler/converters/fullFieldPath'

/**
 *
 * @param {Int} delay
 * sleep for delay microseconds
 */

export function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

/**
  {
    'exp': 1592308103,
    'iat': 1592307803,
    'jti': 'e9998856-4737-4286-8283-213796810fc4',
    'iss': 'https://auth.openhouse.study/auth/realms/Openhouse',
    'sub': 'dd161efb-ea0f-4733-8840-c8cefb6f46cc',
    'typ': 'Bearer',
    'azp': 'openhouse',
    'session_state': '52008d61-d73f-4abd-bc30-bb2f1eb28db8',
    'acr': '1',
    'realm_access': {
      'roles': [
        'offline_access',
        'admin',
        'uma_authorization'
      ]
    },
    'resource_access': {
      'openhouse': {
        'roles': [
          'admin'
        ]
      },
      'account': {
        'roles': [
          'manage-account',
          'manage-account-links',
          'view-profile'
        ]
      }
    },
    'scope': 'profile',
    'name': 'Ankur Jalan',
    'groups': [
      'offline_access',
      'admin',
      'uma_authorization'
    ],
    'preferred_username': '9836066969',
    'given_name': 'Ankur',
    'family_name': 'Jalan'
  }
*/
// export function tokenDecoder(token = null) {
//   let ACCESS_TOKEN;
//   if (token) {
//     ACCESS_TOKEN = token;
//   } else {
//     ACCESS_TOKEN = localStorage.getItem(ACCESS_TOKEN_KEY)
//       ? localStorage.getItem(ACCESS_TOKEN_KEY)
//       : '';
//   }

//   try {
//     if (ACCESS_TOKEN) {
//       return jwtDecode(ACCESS_TOKEN);
//     }
//     return {};
//   } catch (e) {
//     return {};
//   }
// }
// export const user = () => tokenDecoder();

/**
 *
 * @param {text} text
 * copy text to clipboard
 */
function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }
  document.body.removeChild(textArea)
}

export function copyTextToClipboard(text, successMsg, errorMsg = '') {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    notify(successMsg, ' ', 'success')
    return
  }
  navigator.clipboard.writeText(text).then(
    () => {
      notify(successMsg, ' ', 'success')
    },
    (err) => {
      if (errorMsg) {
        notify(errorMsg, ' ', 'error')
      }
      console.error('Async: Could not copy text: ', err)
    }
  )
}

export function openWhatsapp(text = '') {
  let msgText
  if (isNullOrEmpty(text)) {
    msgText =
      'Hi Openhouse! I have a query about the OH dashboard. Kindly guide me through it.'
  } else {
    msgText = text
  }
  const whatsappLink = `https://api.whatsapp.com/send?phone=+918861115000&text=${msgText}`
  window.open(whatsappLink)
}

export const formatCurrency = (number) =>
  `₹${new Intl.NumberFormat('en-IN', {
    maximumSignificantDigits: 3
  }).format(number)}`

export const filterFullPath = (filters) => {
  const fullPathFilters = []
  /* eslint no-restricted-syntax: ["error", "FunctionExpression",
  "WithStatement", "BinaryExpression[operator='in']"] */
  /* eslint guard-for-in: "error" */
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      fullPathFilters[fullFieldPath(key)] = filters[key]
    }
  }
  return fullPathFilters
}

export const isMobile = () => window.innerWidth <= 500
