import { BATCH_UPDATE_URL } from '../../globals/urls'
import axios from '../../httpInstance/axios'

/**
 * @param {object} payload
 * create batch service
 * @payload
 * {
 *  course: <UUID>,
 *  mode: 'oh_meets' / 'zoom',
 *  batch_type: 'class'/'event'/'mock',
 *  center: <UUID>,
 *  status: 'active' / 'suspended',
 *  enrolment_capacity: <int>,
 *  fee: <int>,
 *  demo_capacity: <int>
 * }
 */

const updateBatchService = async (payload) => {
  const response = await axios.patch(
    `${BATCH_UPDATE_URL}/${payload.uuid}/`,
    payload
  )
  return response.data
}

export default updateBatchService
