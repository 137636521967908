import React from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter } from "react-router-dom";
import { StoreProvider, createStore } from "easy-peasy";
import RouteSwitcher from "./routes/RouteSwitcher";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import { ThemeProvider } from "@material-ui/styles";
import { getTheme } from "./theme";

import globalStore from "./store/globalStore";

const store = createStore(globalStore);

const App = () => (
  <StoreProvider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={getTheme()}>
        <ReactNotification />
        <ErrorBoundary>
          <RouteSwitcher />
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  </StoreProvider>
);

export default App;
