export const setAccessTokenKey = (type) => {
  return `${type.toUpperCase()}-TOKEN-${process.env.REACT_APP_CUSTOM_NODE_ENV.toUpperCase()}`
}

const access_token_key = setAccessTokenKey('OH-ACCESS')
const refresh_token_key = setAccessTokenKey('OH-REFRESH')
const refresh_last_updated_token_key = setAccessTokenKey(
  'OH-REFRESH-LAST-UPDATED'
)
export const ACCESS_TOKEN_KEY = access_token_key // 'OPENHOUSE-ACCESS-TOKEN-KEY'
export const REFRESH_TOKEN_KEY = refresh_token_key //'OPENHOUSE-REFRESH-TOKEN-KEY'
export const REFRESH_TOKEN_LAST_UPDATED = refresh_last_updated_token_key //'OPENHOUSE-REFRESH-TOKEN-LAST-UPDATED'
export const DEFAULT_AUTH_TOKEN =
  'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJESnN4V1lpeFhwYldYaGxFUDd6a3VWUHlaTDI0bXlCRE5XQ21kdTlZckp3In0.eyJleHAiOjE2MDYzNzk5NTksImlhdCI6MTYwMzc4Nzk1OSwianRpIjoiOWYyZTQzM2YtYmUwMC00NzQyLWI3ZjMtY2U0YjhkMTczNTU2IiwiaXNzIjoiaHR0cHM6Ly9hdXRoLm9wZW5ob3VzZS5zdHVkeS9hdXRoL3JlYWxtcy9PcGVuaG91c2UiLCJzdWIiOiI0MGUzNmFhOS01ZWNhLTQwOWItOWY3Yy04MjQ4NmM1NDk4YWQiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJvcGVuaG91c2UiLCJzZXNzaW9uX3N0YXRlIjoiMjU5OGJkMjktZmRlOC00NWZiLTg3NTEtZDdiOTJmODRkN2E4IiwiYWNyIjoiMSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsImZpbmFuY2VfYWRtaW4iLCJhZG1pbiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsib3BlbmhvdXNlIjp7InJvbGVzIjpbImFkbWluIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUiLCJuYW1lIjoiU291cmF2IFB1cmthaXQiLCJncm91cHMiOlsib2ZmbGluZV9hY2Nlc3MiLCJmaW5hbmNlX2FkbWluIiwiYWRtaW4iLCJ1bWFfYXV0aG9yaXphdGlvbiJdLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiI5ODA0MTk0OTU5IiwiZ2l2ZW5fbmFtZSI6IlNvdXJhdiIsImZhbWlseV9uYW1lIjoiUHVya2FpdCJ9.bej3CKLAnLF_IQKd42QBwGYb_KqEolAOKqiyBI1EEABeqhHwSC5kVRekF_-SgHGfV-hPRiIC58WBQX83bA4TacZwftueKcywZBFR4XD_BGR6ZZOu7LWClUWm-mdp-PS5z1OHYvyGAPDj8v_eOuwjzs8z8aovdLOFhnQShIQwQU1HhA0Ez-8g5FzXy_4WobfmkcMAThAJTxdxHcMm3Na6hTXnBjOaQOn1jEQglsfM9DTMAvniaSEFQR8s0BGtCzmVT2zZdRb6OmzWy6eM6KjTSdrU1fTuCn0ojZlvk80NcNLKcx7v2bhjXSWARkb0W5x89Al4s1ySI_wAz8uxDSpyJg'
