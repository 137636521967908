import openhousePalette from './colors'

const palette = {
    primary: {
        main: openhousePalette['oh-orange']
    }
}

export const getPalette = () => {
    return palette
}