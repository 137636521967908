import axios from '../../httpInstance/axios';
import { SCHEDULES_URL, DELETE_SCHEDULES_URL } from '../../globals/urls';

/**
 * @param {object} payload
 * delete schedules single or all
 * @payload
 * {
 *  type: single/all,
 *  data: <UUID>/
 *    {
 *      schedule_uuid: <UUID>,
 *      delete_all_of_batch: <boolean>
 *    }
 * }
 */

const deleteSchedule = async (payload) => {
  const {
    data,
    type
  } = payload;
  if (type === "single") {
    const response = await axios.delete(`${SCHEDULES_URL}/${data}/`);
    return response.data;
  } else {
    const response = await axios.post(DELETE_SCHEDULES_URL, data);
    return response.data;
  }
};

export default deleteSchedule;
