import React from "react";

// components, styles and UI elements
import * as LoaderStyles from "./styles";

/**
 * Used as a placeholder while some other component is
 * fetching data async
 *
 * @param small - displays a compact version of the loader
 */
const Loader = ({ small }) => {
    return (
        <LoaderStyles.Wrapper isSmall={small}>
            <LoaderStyles.Text>oh.</LoaderStyles.Text>
        </LoaderStyles.Wrapper>
    );
};

export default Loader;
