import Cookies from 'universal-cookie'

const cookies = new Cookies()
/**
 * @param {*} key
 * get item by key
 */
export const getItem = (key) => cookies.get(key)
/**
 * @param {string} key 
 * @param {string} value
 * set item by key value
 */
export const setItem = (key, value) => {
  cookies.set(key, value, { path: '/' })
}
/**
 * @param {string} key 
 * remove item by key
 */
export const removeItem = (key) => {
  cookies.remove(key, { path: '/' })
}
/**
 * clear all cookies
 */
export const cleara = () => {
  const allCookies = cookies.getAll()
  for (const key in allCookies) {
    cookies.remove(key, { path: '/' })
  }
}
