import React from 'react';
import ReactDOM from 'react-dom';
// components, styles and UI
import './index.css';
import App from './app/App';

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

// React Hot Loading
render();

if (module.hot) {
  module.hot.accept('./app/App', () => {
    render();
  });
}
