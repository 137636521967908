import { action, thunk} from 'easy-peasy';
import notify from '../helpers/notificationHelper';
import Console from '../helpers/console';
import createTribeAndSchedulesService from '../services/tribe/crud/create';
import deleteTribeService from '../services/tribe/crud/delete';

const tribeModel = {
  // initialStates
  creatingTribe: false,
  tribeDeleted: false,

  // actions
  setCreatingTribe: action((state, payload) => {
    state.creatingTribe = payload;
  }),

  setTribeDeleted: action((state, payload) => {
    state.tribeDeleted = payload;
  }),

  createTribeAndSchedules: thunk(async (actions, payload) => {
    try {
      actions.setCreatingTribe(true);
      const data = await createTribeAndSchedulesService(payload);
      if (data.success) {
        notify('Tribe created/attached successfully', '', 'success');
      }
      actions.setCreatingTribe(false);
      return data;
    } catch (err) {
      Console.log(err);
      notify('Unable to create tribe and schedules', '', 'danger');
      actions.setCreatingTribe(false);
      return err;
    }
  }),

  deleteTribe: thunk(async (actions, payload) => {
    try {
      const response = await deleteTribeService(payload);
      if (response.success) {
        notify('Tribe deleted successfully', '', 'success');
        actions.setTribeDeleted(true);
      } else {
        notify('Unable to delete tribe', '', 'danger');
        actions.setTribeDeleted(false);
      }
      return response;
    } catch (err) {
      Console.log(err);
      notify('Unable to delete tribe', '', 'danger');
      return err;
    }
  }),
}
export default tribeModel;
