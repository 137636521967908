import { queries } from '@testing-library/react'

import { filterFullPath } from '../../../helpers/misc'
import urlencode from '../../../helpers/urlHelpers'
import { CONFIG_APP_URL, SCHEDULES_URL_V4 } from '../../globals/urls'
import axios from '../../httpInstance/axios'

/**
 * @param {object} filters
 * fetch all the filtered schedules
 * @filters
 * {
 *    teacher: <UUID>,
 *    course: <UUID>,
 *    centre: <UUID>,
 *    date_gte: 'YYYY-MM-DD',
 *    date_lte: 'YYYY-MM-DD'
 * }
 */

const fetchAllSchedules = async (filters) => {
  const fullPathFilters = filterFullPath(filters)
  const response = await axios.get(urlencode(SCHEDULES_URL_V4, fullPathFilters))
  return response.data
}

const getCancelReasons = async () => {
  const response = await axios.get(
    urlencode(CONFIG_APP_URL, { key: 'SCHEDULE_CANCELLATION_REASONS' })
  )
  return response.data
}

const fetchScheduleAttendance = async ({ queries = {}, uuid } = {}) => {
  const response = await axios.get(
    urlencode(`${SCHEDULES_URL_V4}/${uuid}`, queries)
  )
  return response.data
}

export { fetchAllSchedules, getCancelReasons, fetchScheduleAttendance }
