import { isEmpty } from 'lodash'

import { isNullOrEmpty } from '../../../helpers/emptyHelper'
import { batchTypeColors } from '../../../theme/scheduleColors'

/**
 * @param {object} schedules array of schedule objects
 * converts schedule object to event object with proper format
 */
const scheduleToEvents = (schedules) => {
  try {
    let res
    if (!isNullOrEmpty(schedules)) {
      const newSchedules = schedules.results.map((schedule) => {
        if (schedule.batch?.uuid) {
          res = {
            id: schedule.uuid,
            start: `${schedule.date}T${schedule.start_time}`,
            end: `${schedule.date}T${schedule.end_time}`,
            title: isEmpty(schedule?.batch?.teacher)
              ? `${schedule?.batch?.course?.name}`
              : `${schedule?.batch?.teacher?.full_name} - ${schedule?.batch?.course?.name}`,
            description: `${schedule?.room?.name}`,
            resourceId: schedule?.room?.uuid,
            extendedProps: {
              schedule: schedule
            },
            backgroundColor:
              batchTypeColors[
                schedule?.cancelled ? 'disabled' : schedule.batch.type
              ]?.fill,
            borderColor:
              batchTypeColors[
                schedule?.cancelled ? 'disabled' : schedule.batch.type
              ]?.border,
            eventTimeFormat: {
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: true,
              meridiem: 'short'
            }
          }
        } else {
          res = {
            id: schedule.uuid,
            start: `${schedule.date}T${schedule.start_time}`,
            end: `${schedule.date}T${schedule.end_time}`,
            title: `${schedule.event_title}`,
            resourceId: schedule.room?.uuid,
            extendedProps: {
              schedule: schedule
            },
            color: 'var(--oh-purple-new)'
          }
        }
        return res
      })
      return newSchedules
    }
    return []
  } catch (err) {
    console.log(err)
    return []
  }
}

export default scheduleToEvents
