import { action, thunk } from 'easy-peasy'
/**
 * Modals are now controlled through globalStore
 *
 * Each modal instance with setter getter will be found here
 */
/* eslint no-param-reassign: "error" */
const modalsModel = {
  // initialStates
  eventDetailsModalIsOpen: false,
  batchEditModalIsOpen: false,
  eventCreateModalIsOpen: false,
  eventUpdateModalIsOpen: false,
  eventCancelModalIsOpen: false,
  batchCreateModalIsOpen: false,
  tribeCreateModalIsOpen: false,
  attachTribeModalIsOpen: false,
  attachBatchModalIsOpen: false,
  enrolmentsModalIsOpen: false,
  sessionDetailsModalOpen: false,

  // actions
  setEventDetailsModalOpen: action((state, payload) => {
    state.eventDetailsModalIsOpen = payload
  }),
  setBatchEditModalOpen: action((state, payload) => {
    state.batchEditModalIsOpen = payload
  }),
  setEventCreateModalOpen: action((state, payload) => {
    state.eventCreateModalIsOpen = payload
  }),
  setEventUpdateModalOpen: action((state, payload) => {
    state.eventUpdateModalIsOpen = payload
  }),
  setEventCancelModalOpen: action((state, payload) => {
    state.eventCancelModalIsOpen = payload
  }),
  setBatchCreateModalOpen: action((state, payload) => {
    state.batchCreateModalIsOpen = payload
  }),
  setTribeCreateModalOpen: action((state, payload) => {
    state.tribeCreateModalIsOpen = payload
  }),
  setAttachTribeModalOpen: action((state, payload) => {
    state.attachTribeModalIsOpen = payload
  }),
  setAttachBatchModalOpen: action((state, payload) => {
    state.attachBatchModalIsOpen = payload
  }),
  setEnrolmentsModalOpen: action((state, payload) => {
    state.enrolmentsModalIsOpen = payload
  }),
  setSessionDetailsModalOpen: action((state, payload) => {
    state.sessionDetailsModalOpen = payload
  }),

  /**
   * Closes all modals at one go
   */
  closeAllModals: thunk((actions, payload) => {
    actions.setEventDetailsModalOpen(false)
    actions.setEventCreateModalOpen(false)
    actions.setEventUpdateModalOpen(false)
    actions.setEventCancelModalOpen(false)
    actions.setBatchEditModalOpen(false)
    actions.setBatchCreateModalOpen(false)
    actions.setTribeCreateModalOpen(false)
    actions.setAttachTribeModalOpen(false)
    actions.setEnrolmentsModalOpen(false)
    actions.setSessionDetailsModalOpen(false)
  })
}

export default modalsModel
