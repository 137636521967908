import styled, { keyframes } from "styled-components";

const fading = keyframes`
  0% {
    opacity: 0.8;
    transform: scale(1);
  }
  10% {
    opacity: 0.85;
    transform: scale(1);
  }
  20% {
    opacity: 0.9;
    transform: scale(1);
  }
  40% {
    opacity: 0.95;
    transform: scale(1);
  }
  60% {
    opacity: 1;
    transform: scale(1.1);
  }
  70% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 1;
    transform: scale(1.1);
  }
  90% {
    opacity: 0.95;
    transform: scale(1);
  }
  100% {
    opacity: 0.85;
    transform: scale(1);
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Avenir;

  font-size: 5rem;
  color: var(--oh-orange);
  font-weight: bold;
  animation: ${fading} 1s ease-in infinite;

  @media (max-width: 500px) {
    font-size: 60px;
  }
`;

export const Wrapper = styled.div`
  position: ${(p) => (p.isSmall ? "block" : "absolute")};
  width: 100%;
  height: ${(p) => (p.isSmall ? "15rem" : "calc(100vh - 4rem)")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
    width: 100%;
    height: calc(100vh - 8rem);
  }
`;
