import React from 'react'
import { Route } from 'react-router-dom'
import {
  unauthenticated_routes,
  default_route,
} from '../RoutesList/RoutesList'
import Loader from '../../components/common/Loader/Loader'

const UnAuthenticatedRoutes = () => (
  <React.Suspense fallback={<Loader small />}>
    {unauthenticated_routes.map(({ path, component }, key) => (
      <Route exact path={path} component={component} key={key} />
    ))}
    <Route path={default_route.path} component={default_route.component} />
  </React.Suspense>
)

export default UnAuthenticatedRoutes
