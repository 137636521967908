import { isEmpty, isNullOrEmpty } from './emptyHelper'
/**
 *
 * @param {string} baseUrl the main url
 * @param {object} params with a proper key value pair
 * params example is {name: 'Hello', search: 'man'}
 * if base url is https://google.com
 * the @return output string will be
 * https://google.com?name=Hello&search=man
 * if in the params object a value is '' then that key
 * value pair will be ignored while creating the url
 *
 * This also returns the encoded url
 */
const urlencode = (baseUrl, params, allowZero) => {
  const keys = Object.keys(params)
  const values = Object.values(params)
  const getParams = []
  for (let i = 0; i < keys.length; i += 1) {
    if (!isEmpty(values[i]) || (allowZero && values[i] === 0)) {
      getParams.push(`${keys[i]}=${values[i]}`)
    }
  }
  const finalGetParams = getParams.join('&')
  return encodeURI(`${baseUrl}?${finalGetParams}`)
}

export default urlencode
