import urlencode from '../../helpers/urlHelpers'

/**
 * BASE URL of the app
 */
export const BASE_URL = process.env.REACT_APP_BASE_URL
/**
 * User auth urls
 */
export const apiURLOriginV4 = process.env.REACT_APP_BASE_URL_V4
export const SEND_OTP_USER = 'auth/send_otp'
export const VERIFY_OTP_USER = 'auth/verify_otp'
/**
 * All model related urls
 */
export const SCHEDULES_URL = 'oh_schedules/schedule'
export const TEACHER_ATTENDANCE_URL = '/oh_classes/teacher_attendance'
export const DASHBOARD_SCHEDULES_URL = 'oh_schedules/dashboard'
export const BATCH_URL = 'oh_classes/batch'
export const BATCH_UPDATE_URL = 'oh_classes/batch'
export const COURSE_URL = 'oh_classes/course'
export const TRIBE_URL = 'oh_clubs/tribe/alfred'
export const CLUB_URL = 'oh_clubs/club'
export const ROOM_URL = 'commons/room'
export const CENTRE_URL = 'commons/centre'
export const CONFIG_APP_URL = 'config/app'
export const SCHEDULES_URL_V4 = `${apiURLOriginV4}/oh_schedules/schedule`

/**
 * All the autocomplete urls are here
 */
export const TEACHER_AUTOCOMPLETE_URL = 'oh_users/teacher'
export const COURSE_AUTOCOMPLETE_URL = COURSE_URL
export const LEVEL_AUTOCOMPLETE_URL = urlencode(CONFIG_APP_URL, {
  key: 'EXCEL_COURSE_LEVELS'
})
export const CLUB_AUTOCOMPLETE_URL = CLUB_URL
export const CENTRE_AUTOCOMPLETE_URL = CENTRE_URL
export const ROOM_AUTOCOMPLETE_URL = '/oh_schedules/availability'

/**
 * All custom urls
 */
export const CREATE_BATCH_AND_SCHEDULES_URL = 'oh_schedules/batch/create'
export const CREATE_TRIBE_AND_SCHEDULES_URL = 'oh_schedules/tribe/create'
export const DELETE_SCHEDULES_URL = 'oh_schedules/schedule/delete'
export const EDIT_SCHEDULES_URL = 'oh_schedules/schedule/edit'
export const CANCEL_SCHEDULES_URL = 'oh_schedules/schedule/'
export const SESSION_DETAILS_EDIT_URL = 'oh_schedules/schedule_activity/'
