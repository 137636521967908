import { action, thunk} from 'easy-peasy';
import notify from '../helpers/notificationHelper';
import Console from '../helpers/console';
import fetchCourses from '../services/course/crud/fetch';
/**
 * Course Model refers to all the api calls
 * and functionality related to course table operations
 * 
 * Simple crud operations to complex joining operations
 * related to course are here and will be added here
 */
/* eslint no-param-reassign: "error" */
const courseModel = {
  // initialStates
  courses: [],

  // actions
  setCourses: action((state, payload) => {
    state.courses = payload;
  }),

  /**
   * @param {object} payload
   * fetch all the filtered courses
   * @payload
   * {
   *  teacher__user__uuid: <UUID>,
   * }
   */
  getCourses: thunk(async (actions, payload) => {
    try {
      const data = await fetchCourses(payload);
      actions.setCourses(data); // 👈 used to set schedules
      return data;
    } catch (err) {
      Console.log(err);
      notify('Unable to fetch courses', '', 'danger');
      return err;
    }
  })
};

export default courseModel;
