import { DEBUG } from '../services/globals/settings';
/**
 * 
 * @param {*} data
 * Custom console for debugging log
 */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
const log = (data) => {
  if (DEBUG) {
    console.log(data);
  }
};
/**
 * 
 * @param {*} data
 * Custom console for debugging warnings
 */
const warn = (data) => {
  if (DEBUG) {
    console.warn(data);
  }
};
/**
 * 
 * @param {*} data
 * Custom console for debugging error
 */
const error = (data) => {
  if (DEBUG) {
    console.error(data);
  }
};
/**
 * 
 * @param {*} data
 * Consoles all together
 */
const Console = {
  log,
  warn,
  error,
};

export default Console;
